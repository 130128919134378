<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side">
        <div class="inner-wrapper" data-anima="top">
          <span class="logo mb-5">
            <img src="@/assets/logo.png" />
          </span>

          <div class="voltar-login">
            <router-link to="/"
              ><img src="@/assets/img/icons/arrow-page.svg" />
              Voltar</router-link
            >
          </div>

          <b-form @submit.prevent="onSubmit" novalidate>
            <b-form-group label="Nome completo" label-for="name">
              <b-form-input
                id="name"
                placeholder="Seu nome completo"
                v-model="name"
                type="text"
                name="name"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('name') : null"
              >
                Por favor, insira seu nome completo.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Telefone" label-for="phone">
              <b-form-input
                id="phone"
                name="phone"
                v-model="phone"
                type="text"
                placeholder="Seu telefone"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('phone')">
                Por favor, insira o seu telefone
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Você já vende pela Internet?"
              label-for="seller_type"
            >
              <b-form-select
                v-model="seller_type"
                :options="seller_type_options"
                name="seller_type"
                id="seller_type"
                placeholder="Por favor, selecione uma opção"
                v-validate="'required'"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Por favor, selecione uma opção</b-form-select-option
                  >
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="!errors.has('seller_type')">
                Por favor, selecione uma opção
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Você vai vender seus produtos ou como Afiliado?"
              label-for="affiliated_options"
            >
              <b-form-select
                v-model="affiliated"
                :options="affiliated_options"
                name="affiliated_options"
                id="affiliated_options"
                placeholder="Por favor, selecione uma opção"
                v-validate="'required'"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Por favor, selecione uma opção</b-form-select-option
                  >
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="!errors.has('affiliated_options')"
              >
                Por favor, selecione uma opção
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="CPF" label-for="cpf">
              <b-form-input
                id="cpf"
                placeholder="000.000.000-00"
                v-model="cpf"
                type="text"
                name="cpf"
                v-mask="['###.###.###-##']"
                v-validate="'required|cpf_cnpj'"
              ></b-form-input>
              <!-- v-mask="['###.###.###-##', '##.###.###/####-##']" -->
              <b-form-invalid-feedback
                :state="submit ? !errors.has('cpf') : null"
              >
                Por favor, insira seu CPF/CNPJ.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="CNPJ (opcional)" label-for="cnpj">
              <b-form-input
                id="cnpj"
                placeholder="00.000.000/0000-00"
                v-model="cnpj"
                type="text"
                name="cnpj"
                v-mask="['##.###.###/####-##']"
                v-validate="'cpf_cnpj'"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="E-mail" label-for="email">
              <b-form-input
                id="email"
                placeholder="Seu e-mail"
                v-model="email"
                type="email"
                name="email"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('email') : null"
              >
                Por favor, insira seu e-mail.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Sua senha" label-for="password">
              <b-form-input
                id="password"
                placeholder="••••••••"
                v-model="password"
                type="password"
                name="password"
                v-validate="'required|min:8'"
              ></b-form-input>
              <span
                class="mostrar-senha"
                ref="mostrar_senha"
                @click="mostrarSenha"
                >Mostrar</span
              >
              <b-form-invalid-feedback
                :state="submit ? !errors.has('password') : null"
              >
                Por favor, insira sua senha, mínimo 8 caracteres.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label=""
              label-for="accepted_terms"
              class="termos-uso"
            >
              <b-form-checkbox
                id="accepted_terms"
                v-model="accepted_terms"
                name="accepted_terms"
                v-validate="'required'"
              >
                <p class="text-termos">
                  Eu concordo com os
                  <a
                    href="https://www.voomp.com.br/termos-de-uso-greenn/"
                    target="_blank"
                    >termos de uso
                  </a>
                  da Voomp.
                </p>
              </b-form-checkbox>
            </b-form-group>

            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block col-12"
              >
                <Captcha
                  ref="Captcha"
                  v-if="accepted_terms"
                  @changeVerify="changeVerify"
                >
                  <BaseButton
                    variant="secondary"
                    ref="button"
                    type="submit"
                    class="btn-submit mb-5"
                    @click="onSubmit"
                  >
                    Criar minha conta
                  </BaseButton>
                </Captcha>
                <BaseButton
                  v-else
                  variant="secondary"
                  ref="button"
                  disabled
                  type="submit"
                  class="btn-submit mb-5"
                  @click="onSubmit"
                >
                  Criar minha conta
                </BaseButton>
              </b-overlay>
            </b-row>
          </b-form>
        </div>
      </b-col>

      <b-col cols="12" md="6" class="right-side"> </b-col>
    </b-row>
  </b-container>
</template>
<script>
import SellerRegisterService from "@/services/resources/SellerRegisterService";
const service = SellerRegisterService.build();

import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import Captcha from "@/components/security/Captcha.vue";
// import crisp from "@/mixins/crisp.js";

export default {
  name: "Login",
  components: {
    Captcha,
  },
  data() {
    return {
      submit: false,
      name: "",
      email: "",
      password: "",
      cpf: "",
      cnpj: "",
      phone: "",
      accepted_terms: false,
      success: true,
      loading: false,
      hash: null,
      id_product: null,
      id_indicacao: null,
      seller_type: null,
      affiliated: null,
      affiliated_options: [
        { value: "produtos", text: "Meus Produtos" },
        { value: "afiliado", text: "Como Afiliado" },
      ],
      seller_type_options: [
        { value: "zero", text: "Ainda não" },
        { value: "1-a-10", text: "1k à 10k mês" },
        { value: "11-a-50", text: "11k à 50k mês" },
        { value: "51-100", text: "51k à 100k mês" },
        { value: "101-300", text: "101k à 300k mês" },
        { value: "301-999", text: "301k à 999k mês" },
        { value: "1m", text: "+1M mês" },
        {
          value: "nao-informado",
          text: "Já vendo, mas não gostaria de informar",
        },
      ],
    };
  },
  // mixins: [crisp],
  methods: {
    changeVerify(e) {
      this.captcha = e;
      this.onSubmit("get");
    },
    addMeta() {
      let data = {
        meta_key: "onboarding",
        meta_value: 0,
      };

      serviceMeta.create(data);
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = "Mostrar";
      }
    },
    onSubmit(get) {
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;

          let data = {
            name: this.name,
            email: this.email,
            password: this.password,
            cellphone: this.phone,
            cpf: this.cpf,
            cnpj: this.cnpj,
            seller_type: this.seller_type,
            affiliated: this.affiliated,
            terms: this.accepted_terms,
            captcha: this.captcha,
          };

          if (this.id_indicacao) {
            data.confio = this.id_indicacao;
          }

          service
            .create(data)
            .then((resp) => {
              if (this.hash) {
                this.$router.push(
                  `/recrutamento/${this.hash}/?p_id=${this.id_product}&manager=${this.manager}&confirmOpen=open`
                );
              } else {
                this.$router.push(`/verificar-email${window.location.search}`);
              }
            })
            .catch((err) => {
              this.$refs.Captcha.resetCaptcha();
              if (
                err.response.status == 422 &&
                err.response.data &&
                err.response.data.email.length
              ) {
                const h = this.$createElement;
                const a = h(
                  "a",
                  {
                    on: {
                      click: (e) => {
                        e.preventDefault();
                        this.$router.push({
                          path: "/",
                          query: {
                            email: this.email,
                          },
                        });
                      },
                    },
                    attrs: {
                      href: "/",
                    },
                  },
                  "Clique aqui para fazer login"
                );

                this.$bvToast.toast([a], {
                  title: "Você já possui uma conta",
                  variant: "info",
                  noAutoHide: true,
                  appendToast: true,
                });
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    installTags() {
      // tags facebook
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );

      fbq("init", "783302982379407");
      fbq("track", "PageView");

      // fim tags facebook

      // ------

      // tags google
      let googleScript = document.createElement("script");
      googleScript.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=AW-669444824"
      );
      googleScript.async = true;
      document.head.appendChild(googleScript);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-669444824");
      // fim tags google

      // inicio pixel tiktok

      !(function(w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = (w[t] = w[t] || []);
        (ttq.methods = [
          "page",
          "track",
          "identify",
          "instances",
          "debug",
          "on",
          "off",
          "once",
          "ready",
          "alias",
          "group",
          "enableCookie",
          "disableCookie",
        ]),
          (ttq.setAndDefer = function(t, e) {
            t[e] = function() {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          });
        for (var i = 0; i < ttq.methods.length; i++)
          ttq.setAndDefer(ttq, ttq.methods[i]);
        (ttq.instance = function(t) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
            ttq.setAndDefer(e, ttq.methods[n]);
          return e;
        }),
          (ttq.load = function(e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            (ttq._i = ttq._i || {}),
              (ttq._i[e] = []),
              (ttq._i[e]._u = i),
              (ttq._t = ttq._t || {}),
              (ttq._t[e] = +new Date()),
              (ttq._o = ttq._o || {}),
              (ttq._o[e] = n || {});
            var o = document.createElement("script");
            (o.type = "text/javascript"),
              (o.async = !0),
              (o.src = i + "?sdkid=" + e + "&lib=" + t);
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(o, a);
          });

        ttq.load("C5O60ULO3VNUQLVLNK00");
        ttq.page();
      })(window, document, "ttq");

      // fim pixel tiktok
    },
  },
  mounted() {
    // add script
    this.installTags();

    this.initCrisp();
    // fim crisp

    if (typeof this.$route.query.hash_a != "undefined") {
      this.hash = this.$route.query.hash_a;
    }
    if (typeof this.$route.query.p_id != "undefined") {
      this.id_product = this.$route.query.p_id;
    }
    if (typeof this.$route.query.confio != "undefined") {
      this.id_indicacao = +this.$route.query.confio;
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: $base-color;
}
.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}

.right-side {
  background-image: url("~@/assets/img/cadastro-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.left-side {
  background: #fff;
  padding: 0 !important;
  height: 100%;

  .inner-wrapper {
    margin-top: 20px;
    // width: 60%;
    padding: 1% 18%;
    .logo {
      img {
        width: 100px;
        height: auto;
        margin-bottom: 30px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .inner-wrapper form {
    padding-bottom: 40px;
  }
  .inner-wrapper {
    padding: 6% 11% !important;
  }
  .grecaptcha-badge {
    right: -194px !important;
  }
}
.btn-submit {
  width: 100%;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
.voltar-login {
  // max-width: 80px;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #ededf0;
}
.voltar-login a {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 15px;
  max-width: 80px;
}
.voltar-login img {
  transform: rotate(180deg);
  margin-right: 20px;
  margin-top: -2px;
}

@media screen and (max-height: 946px) {
  .full-height {
    height: 100% !important;
  }
}
.termos-uso {
  margin-left: 25px;
}
.text-termos {
  cursor: pointer !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.text-termos a {
  color: #2a63ab;
}
</style>
